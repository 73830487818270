@media only screen and (min-width: 768px) {
    #Top_bar .logo{display:inline-block;width:28%}
    #Top_bar .menu>li>a span:not(.description){font-size:16px;font-weight:600;}
    .button-flat a.button:after, .button-flat a.action_button:after, .button-flat a.tp-button:after, .button-flat button:after, .button-flat input[type=submit]:after, .button-flat input[type=reset]:after, .button-flat input[type=button]:after {
        content: ""; position: absolute; left: 0; top: 0; height: 100%;width: 100%;z-index: 1;transition: all .3s;background: rgba(0,0,0,.07);opacity: 0; }
    .top_bar_left.clearfix {float: none;position: relative;}
    .wmc-cart-wrapper {width: 270px;position: fixed;z-index: 99999;}
    .minicart-custom {right: 10px !important;top: 32px !important;}
    #Top_bar .menu > li > a{display: block;line-height: 60px;position: relative;}
}
@media only screen and (min-width: 1240px){
    .section_wrapper, .container {max-width: 1102px;}
    #Top_bar .menu_wrapper {float: right;}
    #Top_bar #menu {display: block !important;}
    .menuo-no-borders #Top_bar .menu > li > a span {border-width: 0!important;}
}

body{color:#626262;}
#logo{height:100px;padding: 15px 0;max-width: 100%;line-height:100px;}
.header-logo {vertical-align: middle;max-height: 100%;max-width: 100%;height: auto;object-fit: contain;}
#Top_bar {
    /* position: absolute;
    left: 0;
    top: 0px; */
    width: 100%; z-index: 30;}
#Top_bar .logo {margin: 0 30px 0 10px !important;float:left;}
#Top_bar #menu {z-index: 201;}
#Top_bar ul {list-style: none;}
#Top_bar .menu_wrapper {float: left;z-index: 201;}
#Top_bar .menu>li:not(.mfn-megamenu-parent) {position: relative;}
#Top_bar .menu>li { margin: 0;z-index: 203;display: block; float: left;}
.section_wrapper, .container { max-width: 1220px;margin: 0 auto; position: relative;}
#Top_bar .column {margin-bottom: 0;}
.column, .columns {float: left;margin: 0 1% 40px;}
#Top_bar .menu>li>a:hover span{color: #97cc02;}
#Top_bar .menu>li>a:hover {color: #444;}
#Top_bar .menu > li > a {padding: 25px 0;color: #444;text-decoration: none;}
#Top_bar .menu > li > a span:not(.description) {padding: 0 9px !important;}
#Top_bar .menu>li>a span:not(.description) {
    display: block;line-height: 60px;padding: 0 20px;white-space: nowrap;}
.current-menu-item a span {color: #444444 !important;}

#Top_bar .top_bar_right { top: 0;}
#Top_bar .top_bar_right {padding-left: 15px;}
/* #Top_bar .top_bar_right, .header-plain #Top_bar .top_bar_right {height: 110px;} */
#Top_bar .top_bar_right { float: right;position: relative;    padding: 0 10px 0 20px;}
#Top_bar .top_bar_right:before { background: 0 0;box-shadow: unset;}
/* #Top_bar .top_bar_right:before {
    content: "";
    display: block;
    height: 100%;
    width: 10px;
    position: absolute;
    left: -10px;
    top: 2px;
    box-shadow: inset 7px 0px 8px -6px rgb(0 0 0 / 15%);
    -moz-transform: skewX(0deg) skewY(-25deg);
    -webkit-transform: skewX(0deg) skewY(-25deg);
    -o-transform: skewX(0deg) skewY(-25deg);
    -ms-transform: skewX(0deg) skewY(-25deg);
    transform: skewX(0deg) skewY(-25deg);
} */
#Top_bar .container .top_bar_right_wrapper {top: 35px;}
#Top_bar .container .top_bar_right_wrapper {position: relative;}
#Top_bar a#header_cart { display: none;}
#Top_bar a#header_cart {position: relative;top: 9px;float: left;font-size: 22px;line-height: 22px;margin-right: 5px;}
#Top_bar a.action_button {top: -2px;margin: 0 5px;}
.button-flat a.button, .button-flat:not(.header-plain) a.action_button, .button-flat a.tp-button, .button-flat button, .button-flat input[type=submit], .button-flat input[type=reset], .button-flat input[type=button] {
    background-image: none;
    border-width: 0;
    border-style: solid;
    border-radius: 0;
    box-shadow: inset 0 0 0 0;
    -webkit-appearance: none;
}
#Top_bar .container .top_bar_right_wrapper a {
    background: #97cc02;
    color: white !important;
}
#menu > ul > li > a, a.action_button, #overlay-menu ul li a {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
}
.container:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
a.action_button {
    display: block;
    padding: 11px 20px;
    line-height: 21px;
    float: left;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}
.minicart-custom .wmc-cart {
    background: url(https://www.mcaleadsonline.com/wp-content/themes/betheme/images/cart-icon.png) 50% 50% no-repeat;
    width: 50px;height: 50px;}
.minicart-custom .wmc-cart {right: 20px;}
.wmc-cart {position: absolute;cursor: pointer;}
.minicart-custom .wmc-cart img {display: none;}
.minicart-custom .wmc-count {background-color: #162c4e;}
.wmc-count {
    position: absolute;
    right: -10px;
    height: 25px;
    width: 25px;
    border-radius: 100px;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 12px;
    top: 0;
}
.clearfix::after {display:inline;}
/*footer*/
.textwidget li {
    margin-bottom: 15px;font-size:14.5px;
}
.textwidget ul {
    font-weight: 700;
}
.textwidget.custom-html-widget {
    color: #97cc02;font-size:15px;
}
.footer-logo {padding-right:15%;}
@media all{
    div,span,p,a,img,i,ul,li,aside,footer{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
    aside,footer{display:block;}
    ul{list-style:none;}
    p{margin:0 0 15px;}
    i{font-style:italic;}
    a,a:visited,a:focus{text-decoration:none;outline:0;}
    a:hover{text-decoration:underline;}
    p a,p a:visited{line-height:inherit;}
    img.scale-with-grid{max-width:100%;height:auto;float:left;}
    [class^=icon-]:before{font-family:"mfn-icons";font-style:normal;font-weight:400;speak:none;display:inline-block;text-decoration:none!important;width:1em;margin-right:.2em;text-align:center;font-variant:normal;text-transform:none;line-height:1em;margin-left:.2em;}
    .icon-phone:before{content:"\f167";}
    .icon-twitter:before{content:'\f309';}
    .icon-facebook:before{content:'\f30c';}
    .icon-linkedin:before{content:'\f318';}
    .container{max-width:1220px;margin:0 auto;position:relative;}
    .container:after{clear:both;content:" ";display:block;height:0;visibility:hidden;}
    .column{float:left;margin:0 1% 40px;}
    .one-third.column{width:31.333%;}
    .one.column{width:98%;}
    .container:after{content:"\0020";display:block;height:0;clear:both;visibility:hidden;}
    .clearfix{zoom:1;}
    .widget{padding-bottom:30px;margin-bottom:30px;position:relative;}
    .widget:last-child{margin-bottom:0;padding-bottom:0;}
    .widget:last-child:after{display:none;}
    .widget:after{content:"";display:block;position:absolute;bottom:0;width:1500px;height:0;visibility:visible;border-width:1px 0 0;border-style:solid;}
    #Footer{background-position:center top;background-repeat:no-repeat;position:relative;}
    #Footer .widgets_wrapper{padding:15px 0;}
    #Footer .widgets_wrapper .column{margin-bottom:0;}
    #Footer .widgets_wrapper .widget{padding:15px 0;margin-bottom:0;}
    #Footer .widgets_wrapper .widget:after{display:none;}
    #Footer .footer_copy{border-top:1px solid rgba(255,255,255,.1);font-size:15px;}
    #Footer .footer_copy .one{margin-bottom:20px;padding-top:30px;min-height:33px;}
    #Footer .footer_copy .copyright{float:left;}
    #Footer .footer_copy .social{float:right;margin-right:20px;}
     #Footer .footer_copy{text-align:center;}
     #Footer .footer_copy .copyright{float:none;margin:0 0 10px;}
     #Footer .footer_copy .social{float:none;margin:0;}
    .image_frame{display:block;margin:0;border-width:8px;border-style:solid;max-width:100%;line-height:0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
    .image_frame .image_wrapper{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
    .image_frame .image_wrapper{position:relative;overflow:hidden;}
    .image_frame .image_wrapper img:not(.ls-l){position:relative;top:0;-webkit-transform:scale(1);-moz-transform:scale(1);-o-transform:scale(1);transform:scale(1);}
    .image_frame:hover .image_wrapper img{top:-15px;}
    .image_frame.no_link:hover .image_wrapper img{top:0;}
    .image_frame.no_border{border-width:0;}
    .image_frame .image_wrapper img{-webkit-transition:all .3s ease-in-out;transition:all .3s ease-in-out;}
    hr{display:block;border:none;outline:none;height:1px;width:100%;margin:0 auto 15px;clear:both;}
    hr{margin:0 auto 15px;clear:both;}
    hr.no_line{background:0 0;color:transparent;}
    ul{list-style:none outside;}
    ::-moz-selection{color:#fff;}
    ::selection{color:#fff;}
    .widget:after{border-color:rgba(0,0,0,.08);}
    hr{background-color:rgba(0,0,0,.08);color:rgba(0,0,0,.08);}
    .image_frame{border-color:#f8f8f8;}
    .image_frame .image_wrapper{border-color:#e2e2e2;}
    /* @media only screen and (min-width:960px) and (max-width:1239px){
    .container{max-width:940px;}
    } */
    /* @media only screen and (min-width:768px) and (max-width:959px){
    .container{max-width:708px;}
    } */
    @media only screen and (max-width:767px){
    .container .column{margin:0 !important;width:100%!important;clear:both;}
    .widget:after{width:100%;}
    #Footer .footer_copy{text-align:center;}
    #Footer .footer_copy .copyright{float:none;margin:0 0 10px;}
    #Footer .footer_copy .social{float:none;margin:0;}
    .image_frame{margin-top:0!important;}
    }
    #Footer{background-color:#151a36;}
    a{color:#97cc02;}
    *::-moz-selection{background-color:#97cc02;}
    *::selection{background-color:#97cc02;}
    #Footer{color:#ccc;}
    #Footer a{color:#97cc02;text-decoration: none;}
    #Footer a:hover{color:#88b900;}
    #Footer .widgets_wrapper{padding:40px 0 20px;}
    .column{margin-bottom:20px;}
    }
    /*! CSS Used from: Embedded */
    @media only screen and (max-width: 767px){
    .container{max-width:100% !important;}
    }
    #Footer .widgets_wrapper{padding:100px 0;}
    /*! CSS Used from: Embedded */
    .icon-facebook{color:#97cc02;}
    .icon-twitter{color:#97cc02;}
    #Footer .textwidget ul li span{display:none;}
    #Footer .widgets_wrapper{padding:20px 0px 0px;}
    #Footer .footer_copy .one{margin-bottom:10px;padding-top:20px;}
    /*! CSS Used fontfaces */

/* search page */
h3.page-title {font-weight: 500;color: #000;}
.from-to-sec{ display: flex;justify-content: space-between;}
/* .from-to-sec div{padding: 0px 1%;} */
/* .block-split .form-group label {color: #000;font-weight: 500;} */
input.btn.btn-primary {background-color: #97cc02;color: #fff;border-color: #97cc02;border-radius: 5px;font-size: 18px;}
.form-group.btn-sec {padding: 0px 0px;text-align: right;}
.from-to-sec div {margin: 0px 1% 0px 0px;}
/* .search-page form{box-shadow: 0px 9px 13px 1px #b8abab;border-radius:5px;} */

@media only screen and (min-width:768px){
    .block-split.two .form-group {padding: 0px 1%;}
    .block-split .form-group {padding: 0px 1% 0%;}
	h3.page-title{margin:30px 0px;}
	/* .block-split {margin-bottom:20px;} */
	.block-split .form-group {margin: 15px 0px 0px;}
	.block-split {display: flex;}
	.block-split .form-group {width: 50%;}
	.block-split.two .form-group {width: 100%;}
	.from-to-sec div{width:48%}
}
@media only screen and (max-width:767px) {
	.form-group.btn-sec {padding:10px 0px !important;}
	#Top_bar .logo {width:50%;}
}
.error-result {padding-bottom: 0px;text-align:center;margin-bottom:0px;}
.err-msg{color:red;display: block;font-size:12px;padding:2px 0px;}
.search-page .err-msg {min-height: 22px;padding: 5px 0px;}
.search-page .form-group.btn-sec{padding:10px 20px;}
/* search page */
/* customer page */
@media only screen and (min-width:768px){
    .customer-page .container { width: 100%;max-width: 1220px;}
    .block-half {display: flex;justify-content: space-between;flex-direction: row;gap: 10px;}
    .block-half .form-group {width: 100%;}
    .block-half .form-group label {width: 30%;}
    /* .customer-page .block-half .form-group input {margin-left: 5px;} */
    .block-half {
        /* border-bottom: 0.5px solid #bcbcbc;  */
        padding: 8px 0px;}
    .customer-page form {padding: 25px; box-shadow: 0px 9px 13px 1px #b8abab;border-radius: 5px;}
    .block-half .form-group>div {display: flex;}
    .customer-page .err-msg {padding: 3px 0px;max-width: 76%;}
}
@media only screen and (max-width:1024px) and (min-width:768px){
    /* .customer-page .block-half .form-group input {margin-left: 5px;} */
}
@media only screen and (max-width:767px) {
    .customer-form {padding: 10px 0px; }
    .block-half .form-group {display:grid;}
}
.block-half .form-group {margin: 5px 0px 0px;}
/* .block-half .form-group label {color: #000;font-weight: 500;} */
.customer-page .form-group.btn-sec {margin-top: 15px;}
.customer-page .err-msg{
    /* height:20px; */
    padding: 3px 0px;}

input.check.btn {float: right;display: inline-block;width: 28%;
    font-size: 15px;color: #fff;border-radius: 0px;padding: 10.5px;
    background-color: #97cd39;margin-left: 0px !important;}
.search-detail .input-group {display: inline;width: 71%;float: left;}
.search-detail {display: block;width: 100%;}
.search-detail .input-group input{
display: inline-block;
width: 86%;
margin-top: 1px;
}
.search-detail .input-group>.input-group-text {display:inline-block;}
@media only screen and (max-width:500px){
    .search-detail .input-group input {margin-top:0px;width:80%;}
}
/* customer page */
/*plan page*/


@media only screen and (min-width:768px){
    h3.page-title {font-size:32px;}
    p.page-subtitle {font-size: 22px;}
    .policy-details {margin-left: 4%;display: flex;flex-wrap: wrap;align-items: center;}
    .policy-details>div {width: 50%;padding: 10px 0px;display: flex;justify-content: flex-start;}
    .policy-details>div img {padding-right: 10px;}
    .policy-details>div p { margin: 10px 0px;display: inline-block;line-height: 2;}
    .plan-section .column_attr.clearfix.align_center {width: 48%;}
    .plan-section .column_attr h4{font-size: 20px;line-height: 26px;padding-top: 5px;}
    .plan-section {display: flex;flex-wrap: wrap;justify-content:center;}
    .plan-section .column_attr h4 {margin-bottom:30px;}
    .search-page .container {max-width:1220px;}
}
@media only screen and (max-width:767px){
    p.page-subtitle {font-size: 16px;}
    .plan-section .column_attr h4 {margin-bottom:20px;}
    .policy-details>div {padding: 10px 0px;display: flex;justify-content: flex-start;}
    .policy-details>div img {padding-right: 10px;}
    .policy-details {padding: 0px 5px;}
    .policy-details>div p { margin: 10px 0px;display: inline-block;line-height: 2;}
    .plan-section .column_attr.clearfix.align_center{margin: 2% 1%;}
}
.plan-section .column_attr h2{margin-bottom:20px;}
h3.page-title.center {text-align: center;}
p.page-subtitle {text-align: center;color: #3e3e3e;font-weight: 400;}
.plan-section .column_attr.clearfix.align_center {background-color: #162c4e;margin: 1%;padding: 30px;text-align: center;}
.plan-section .column_attr h2 {color: #fff; }
.plan-section .column_attr h4{color: #97cc02;font-weight: 700;}
.plan-list-btn-out input.btn.btn-primary {font-size: 16px;padding: 10px 20px;}
.plan-section .column_attr.clearfix.align_center{position:relative;overflow: hidden;}
.column_attr:nth-child(even) .plan-strip {background-color: #4bbbe2;}
.plan-strip {font-family: "Roboto", Arial, Tahoma, sans-serif;background-color: #97cc02;
    float: left;transform: rotate(312deg);transform-origin: top left;min-width: 140px;color: white; position: absolute;
    transform-origin: bottom left;font-size: 14px;left: 0px;top: 72px;text-align: center;line-height: 32px;}
.search-page {padding-bottom: 30px;}
.column_attr.clearfix.align_center.show h4 {margin-bottom: 10px;}
.column_attr.clearfix.align_center.hide {
    display: none;
}
/* thank you page */
.thankyou-page {text-align: center;padding: 40px 20px;}
.thankyou-page h3.page-title.center {font-family: cursive; font-size: xx-large;}
p.greeting {color: #98ce3a;font-size: 19px;font-weight: 300;margin: 20px 0px;}
p.thank-content {color: #878585;font-weight: 300;font-size: 18px;}
.thankyou-page .container div {padding: 20px;}
a.home-btn {background-color: #151a36;color: #fff;padding: 15px;border-radius: 25px;}
@media only screen and (max-width:767px){
    img.thank-logo {width: 100px;padding: 10px;}
}
/*28-7-22*/
.search-page .rmsc .gray {color:#8b9298;}
/* .dropdown-heading {background-color: #e9ecef;} */
.rmsc .dropdown-heading .dropdown-heading-value span {color: #8b9298;font-size: .875rem;}
.customer-page {padding-bottom: 30px;}
/*29-7-22*/
@media only screen and (max-width:767px) {
    #Top_bar .logo{margin: 0px !important;}
    #Top_bar {background-color: #fff;border-bottom: 0.5px solid #f2f2f2;}
    #logo{line-height:4;}
    #Top_bar .responsive-menu-toggle {color: #97cc02; top: 30px!important;left: auto;right: 10px;margin-top: -17px; position:absolute;}
    #Top_bar .container .menu_wrapper,.container .top_bar_right_wrapper {display: none;}
    .wmc-cart-wrapper {width: 270px;position: fixed;z-index: 99999;right: 0px !important;top: 70px !important;}
    .search-form,.customer-page form {padding: 10px;}
    h3.page-title{margin:20px 0px;}
}
@media only screen and (min-width:768px) {
#Top_bar .top_bar_right {height: 90px;}
span.responsive-menu-toggle {display:none;}
}
/*2-8-22*/
@media only screen and (max-width:767px) {
    .customer-page form {box-shadow: 0px 9px 13px 1px #b8abab; border-radius: 5px;}
    .button-flat:not(.header-plain) a.action_button{background-image:none;border-width:0;border-style:solid;border-radius:0;box-shadow:inset 0 0 0 0;-webkit-appearance:none;}
    #Side_slide{display:block;position:fixed;top:0;right:-250px;max-width:100%;width:250px;height:100%;overflow:auto;border-bottom-width:60px;border-bottom-style:solid;z-index:99999;z-index:100000;}
    #Side_slide .close-wrapper{height:60px;}
    #Side_slide .close-wrapper .close{color: #a6a6a6;height:34px;width:34px;display:block;float:right;margin:13px 13px 0 0;}
    #Side_slide .close-wrapper .close i{font-size:22px;line-height:34px;}
    #Side_slide .extras{padding:0 20px;}
    #Side_slide .extras .action_button{float:none;width:100%;margin:0 0 20px;text-align:center;text-decoration:none;}
    #Side_slide .extras .extras-wrapper{text-align:center;}
    #Side_slide .extras .extras-wrapper a{display:inline-block;text-decoration:none;vertical-align:middle;padding:5px;margin-bottom:20px;line-height:22px;}
    #Side_slide .extras .extras-wrapper .icon{font-size:22px;}
    #Side_slide .extras .extras-wrapper .cart{position:relative;}
    #Side_slide .extras .extras-wrapper .cart span{position:absolute;top:0;left:31px;font-size:11px;border-radius:2px;}
    #Side_slide #menus{display:block!important;margin-bottom:20px;max-height:none!important;}
    #Side_slide #menus ul{width:100%!important;}
    #Side_slide #menus ul li{width:100%;position:relative;border-top:1px solid rgba(255,255,255,.03);}
    #Side_slide #menus>ul:last-child>li:last-child{border-bottom:1px solid rgba(255,255,255,.03);}
    #Side_slide #menus ul li a{display:block;padding:11px 5px 10px 20px;margin-right:50px;text-decoration:none;line-height:19px;}
    #Side_slide .lang-wrapper{margin-bottom:20px;text-align:center;display:none;}
    #Side_slide .social{text-align:center;margin:0 20px 13px;}
    #Side_slide{background-color:#191919;border-color:#191919;}
    #Side_slide,#Side_slide a:not(.action_button){color:#a6a6a6;}
    #Side_slide a:not(.action_button):hover{color:#fff;}
    #Side_slide #menus ul li.current-menu-item>a,#Side_slide #menus ul li:hover>a{color:#fff;}
    .dark{color:#fff!important;}
    #Side_slide{right:-250px;width:250px;}
    .current-menu-item a span{color:#444444!important;}
    #Side_slide .extras .action_button {background-color: #f7f7f7; color: #747474;float: none;width: 100%;margin: 0 0 20px;text-align: center; text-decoration: none;display: block;padding: 11px 20px;line-height: 21px; position: relative; box-sizing: border-box;overflow: hidden;font-size: 13px; letter-spacing: 0px;}
    #menus > ul > li > a, a.action_button, #overlay-menu ul li a {font-size: 13px;letter-spacing: 0px;}
    .hamburger-react > div {height: 2px !important;}
    #Top_bar .right.dark.enabled{display:block;right:0px;}
    #Top_bar .right.dark.disable{display:none;right:0px;}
    img.cancel-toggle {max-width: 18px;}
}
@media only screen and (min-width:768px) {
a.responsive-menu-toggle,div#Side_slide {display: none;}
}


/* icon css */
.input-group {box-shadow: 0 3px 2px rgb(233 236 239 / 5%);border-radius: 0.25rem;transition: all .15s ease-in-out;}
.input-group { position: relative;display: flex;flex-wrap: wrap;align-items: stretch;width: 100%;}
.input-group>.input-group-text{display: flex;    margin-right: -1px;border-top-right-radius: 0;border-bottom-right-radius: 0;border-right: 0;}
.input-group-text {transition: all .15s cubic-bezier(.68,-.55,.265,1.55);}
.form-control-lg, .input-group-lg .form-control, .input-group-text {font-size: 1rem!important;}
.input-group-text {
    display: flex;align-items: center;padding: 0.625rem 0.75rem;margin-bottom: 0; font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
.input-group-text i {font-size: .875rem;}
.fa, .fas {font-weight: 900;}
.input-group-merge .form-control:not(:first-child) { border-left: 0;padding-left: 0;}
.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {border-top-left-radius: 0;
border-bottom-left-radius: 0;}
.input-group>.form-control {position: relative;width: 1%;margin-bottom: 0;flex: 1 1 auto;}
.form-control {font-size: .875rem;transition: all .15s ease-in-out;height: calc(1.5em + 1.25rem + 5px);}
.input-group .form-control {
    display: block;padding: 0.625rem 0.75rem;font-weight: 400;line-height: 1.5;color: #8898aa;background-color: #fff;background-clip: padding-box;border: 1px solid #dee2e6; border-radius: 0.25rem;}
.input-group .form-control, .input-group .form-control:focus {box-shadow: none;}
.block-split .form-group label,.block-half .form-group label{color: #314065;font-size: .875rem;font-weight: 600;}
textarea.form-control {color: #8898aa;}

/* error page */
.error-page { text-align: center;padding: 20px 0px;}
.error-page .home-btn-div { padding: 10px 0px;}
.error-page h3.page-title.center {margin:15px 0px;}
.plan-section.new .column_attr.clearfix.align_center {width:100%;}
/*6-8-*22*/
.search-form { padding: 25px;}
h3.page-title {text-align:center;}
span.display-msg {font-size: 12px;display: block;padding: 5px 0px;}
input.btn.btn-primary.clear {margin-right: 10px;}
/* search redesign */
.milestone .input-group-text {border-radius: 0px;padding: 0px;border:none;line-height:normal;background:unset;display:inline;}
.milestone .input-group-text i:before {font-size:18px;}
.milestone.active .milestone-icon { background: #97cd39 repeat;border-color: #97cd39;}
.milestone.active .milestone-icon .input-group-text{ color: #fff;}
.milestone .milestone-icon .input-group-text{color: #97cd39;}
.milestone .milestone-icon {position: relative;background: #fff repeat;border-radius: 20px;color: #97cd39;left: 0px;display: inline-block;text-align: center;top: 2px;padding: 8px 10px;border: 1px solid #bbb;}
.milestone { position: relative;display: flex;width: 33%;}
.search-liner {position: relative;padding: 20px;display: flex;clear:both;}
.search-liner .bar {height: 8px;top: 18px;position: relative;width:91%;background-color:#bbb;}
.search-liner .milestone.active .bar {background-color: #97cd39;}
.milestone.business-type .bar:first-child {border-top-left-radius: 5px;border-bottom-left-radius: 5px;}
.milestone.salary .bar:last-child {border-top-right-radius: 5px; border-bottom-right-radius: 5px;}
.milestone .milestone-icon p{font-weight: 500;    text-transform: uppercase;position: absolute;color: #888;font-size: 13px;width: 104px;left: -34px;margin: 10px 0px;justify-content: space-around;}
.search-page .search-form {box-shadow: 0px 9px 13px 1px #b8abab;border-radius: 5px;margin: 40px 0px;}
.search-form .block-section.show {display: block;}
.search-form .block-section.hide {display: none;}
.search-form .form-group.business {margin:0 auto;}
.search-form{padding:40px 20px;}
.form-group.btn-sec.next {width: 100%;}
.form-group.btn-sec.address {display: flex;justify-content: space-between;padding: 20px;}
.search-form input.btn.btn-primary{font-size:16px;}
.business-select-value {display: block;padding: 5px 0px;display: flex;margin: 0 auto;flex-wrap: wrap;}
.business-select-value .card-box {color: #000;font-size: 13px;padding: 5px 10px;border-radius: 10px;margin: 5px;background-color: #f2f2f2;}
.form-group.business .dropdown-content ul>.select-item {display:none}
.block-split .form-group label {font-weight: 600;font-size: 18px;padding: 5px;margin: 5px 0px}
.form-group.business {text-align: center;}
.block-split.address {display: block;}
.block-split.address label {font-weight: 600;font-size: 18px;padding: 5px;margin: 5px 0px;}
.block-split.address .rmsc .dropdown-container {border-color: #dee2e6;padding: 3px;}
@media only screen and (min-width:768px) {
    .business-select-value { width: 50%;}
    .block-split.address .form-group { margin: 0 auto;}
    .block-split.two {max-width: 50%;margin: 0 auto;}
}
.search-form input[type=number]::-webkit-inner-spin-button,
.search-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.block-split.address .rmsc .dropdown-heading .dropdown-heading-value .gray:before {
    font-size: .875rem;font-family: "Font Awesome 6 Free"; font-weight: 900;    color: #adb5bd;    content: "\f041";padding-right:5px;margin:0px 2px;
}
.block-split.address .rmsc .dropdown-heading{    padding: 0px 6px;}
.form-group.business .rmsc .dropdown-heading{text-align:left;}
.form-group.business .rmsc .dropdown-heading .dropdown-heading-value .gray:before {
    font-size: .875rem;font-family: "Font Awesome 6 Free"; font-weight: 900;    color: #adb5bd;    content: "\f2bb";padding-right:5px;margin:0px 2px;
}
@media only screen and (max-width:320px) {
    .block-split.two .form-control{padding-right:2px;}
    .block-split.two .form-control::placeholder{font-size:10px;}
}
@media only screen and (max-width:1180px) and (min-width:768px){
    .search-detail .input-group input{width:82%;}
    .search-detail .input-group {width:73%;}
    input.check.btn {width:26%;}
}
@media only screen and (max-width:900px) and (min-width:768px){
    .search-detail .input-group>.input-group-text {padding: 0.6rem 7px;}
    .customer-form .form-group label{font-size:12px;}
}
@media only screen and (max-width:1024px) and (min-width:960px){
#Top_bar .logo { width: 22%;}
}
@media only screen and (max-width:1024px) and (min-width:768px){

}
/* search redesign */
/*12-8-22*/
.pricebar{display: flex;align-items: center;padding:0px 10px 0px 5px;}
.top_bar_right_wrapper a {text-decoration: none;}
.dropdown-content li .select-item span {font-size:15px;}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.loading-spinner.hide {display: none;}
.loading-spinner.show {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 999;
    left: 50%;
    top: 50%;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}
.container.spinner-container:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index:11;
    background-color: rgb(255 255 255 / 66%);
    cursor: none;
}
.block-split.address .dropdown-content ul>.select-item {font-size:15px;}
/*13-8-22*/
.search-detail input#email {border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;}
.otp-section.show {display: flex;width: 100%;clear: both;padding: 5px 0px;gap: 15px;}
@media only screen and (min-width:768px) {
    .otp-section.show input#otp { width: 50%;}
}
@media only screen and (max-width:767px) {
    .otp-section.show input#otp {width: 68%;}
    .search-detail .input-group{display: -webkit-inline-flex;}

}
.otp-section.hide {display:none !important;}
/* .dropdown-content li .select-item span {text-transform: capitalize;} */
/*16-8-22*/
@media only screen and (max-width:330px){
    #Top_bar .logo {width:60% !important;}
}
@media only screen and (max-width:425px) {
.block-split.two input::placeholder {font-size:12px;}
.dropdown-content li .select-item input{width:8%;}
.dropdown-content li .select-item span{width:92%;}
}
.dropdown-content li .select-item span {text-align: left;}
/*22-8-22*/
.lead-page {padding-bottom: 30px;}
.lead-page .form-group.btn-sec {margin-top: 15px;}
.lead-page .err-msg {margin:0 auto;max-width:70%;text-align: left;}
@media only screen and (min-width: 768px) {
.lead-page .err-msg {font-size:13px;}
.lead-page .container {width: 100%;max-width: 1220px;}
.lead-page form { padding: 25px;box-shadow: 0px 9px 13px 1px #b8abab;border-radius: 5px;max-width: 64%;margin:0 auto;}
.lead-form { width: 100%;margin: 0 auto;max-width: 70%;margin-top:15px;}
}
@media only screen and (max-width: 767px){
.lead-form {padding: 10px 0px;}
.lead-page form {box-shadow: 0px 9px 13px 1px #b8abab; border-radius: 5px;}
.lead-page form {padding: 10px;}
}
.form-group input::placeholder {font-weight: 500;}
.form-group textarea::placeholder {font-weight: 500;}
.rmsc .dropdown-heading .dropdown-heading-value span {font-weight: 500;}
/*29-8-22*/
.form-group.referer select {
    display: block;
    padding: 0.75rem;
    font-weight: 500;
    line-height: 1.5;
    color: #6e777f;
    font-size: 15px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
@media only screen and (min-width:768px) {
	.form-group.referer { width: 45%;}
	.form-group.referer .input-group {display: block;width:100%;}
}
.form-group.referer select,.block-half.referer {width:100%;}
.form-group.referer .refer-section.show {display:block;}
.form-group.referer .refer-section.hide {display:none;}
.error-page h1 {
    color: #000;
    padding: 18px 0px;
}
@media only screen and (max-width:325px) {
    .customer-page input.check.btn {
        font-size: 11px;
        line-height: 23px;
    }
}
.note-label{text-align:center;color:#000;}
.note-label span{color:red;}



.total-leads-count {
    color: #225229;
    font-weight: bold;
    font-size: x-large;
    padding-top: 40px;
    margin: 0;
}
.parent-business-types{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.each-business-types{
    width: 33%;
    display: flex;
    font-size: 19px;
    padding: 10px;
}

.each-business-count{
    padding-left: 10px;
    padding-top: 3px;
}

.heading-business-types{
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 30px;
}


.business-leads-button-parent{
    text-align: end;
    padding-right: 55px;
}

.business-leads-button{
    border: none;
    background: white;
    color: blue;
}

.plus_sign{
    color: #ff0000a3;
    /* font-size: 35px;
    font-style: italic; */
}

.each-business-names{
    color: #97cd39;
    font-weight: 400;
    font-size: 20px;
}

.sample-card li {
    list-style: none;
    padding: 5px 0px;
}
.sample-card {
padding-bottom: 20px;
}

.full-cart{
    margin-top: 15px;
    background-color: #fafafa;
    border: 1px solid #888;
    border-radius: 4px;
}
.full-cart h1 {
    text-align: center;
    font-size: revert;
    padding: 10px;
    color: #97cd39;
}

.cart-part-half{
    margin-top: 10px;
    width: 66%;
    margin-left: 30px;
    font-family: "Myriad Web Pro";
}

.display-half{
    display: inline-block;
    vertical-align: top;
}

.cart-part-half h1 {
    margin-top: -5px;
    color: #353638;
}

.cart-part-half p {
    color: #353638;
    margin: 10px 0 10px 0;
    font-size: 10pt;
}

.cart-part-half h2 {
    font-size: 18px;
    margin: 10px 0 10px 0;
    color: #353638;
    text-decoration: underline;
}

.cart-part-half p {
    color: #353638;
    margin: 10px 0 10px 0;
    font-size: 16pt;
}
@media only screen and (min-width:768px) {
    .sample-card {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sample-section {
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-wrap:wrap;
    }
    .sample-section .sampledata {
    width: 30%;
    margin: 10px 10px !important;}
}
.sample-section .sampledata {
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    /* background-color: #f2f2f257; */
    padding: 12px;
    margin: 10px 0px;
    box-shadow: 0px 2px 6px #888;
}
.sample-section .sampledata li {
    list-style: none;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    padding: 1px 0px;
    color: #000;
}
.sample-section .sampledata li b {
    font-weight: 500;
    padding-right: 10px;
}
.sample-section {
    position:relative;
    min-height: 300px;
}
.sample-section::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    backdrop-filter: blur(5px); /* Adjust the blur radius as desired */
    z-index: 1;
  }
  .sample.search-page {
    position: relative;
}
.sample.search-page .sample-list-btn-out {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 60%;
    z-index: 111;
}
.sample.search-page .sample-list-btn-out p {
    font-size: x-large;
    color: #000;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
    .parent-business-types{
        display: block;
    }
    .each-business-types{
        width: 100%
    }

}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .each-business-types{
        width: 50%
    }

}

/* new changes styles 10/10/2023*/

.total-leads-count-content{
    color: gray;
}
